import React, { FC, HTMLAttributes, ReactNode } from "react";
import { Image as BaseImage } from "@components/ui/Image";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
  children?: ReactNode;
}

export const Wrapper: FC<Props> = ({ children, className = "", ...props }) => (
  <div
    className={twMerge(
      `border border-gray-100 overflow-hidden rounded-md shadow-lg mb-4`,
      className
    )}
    {...props}
  >
    {children}
  </div>
);

interface ImageProps {
  src?: string | null;
  alt?: string | null;
  children?: ReactNode;
}

const ImageWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="relative bg-gray-100">{children}</div>
);

export const Image: FC<ImageProps> = ({ src, alt, children }) => {
  if (!src) {
    return (
      <ImageWrapper>
        <div className="w-full bg-gray-200 object-cover h-48" />
        {children}
      </ImageWrapper>
    );
  }

  return (
    <ImageWrapper>
      <div className="relative h-48">
        <BaseImage
          src={src}
          alt={alt || ""}
          className="w-full object-cover h-48"
          fill={true}
          activeCache={true}
        />
      </div>
      {children}
    </ImageWrapper>
  );
};

export const Label: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="inline-block bg-gray-800 text-white rounded-sm px-2 py-1 shadow text-sm absolute bottom-0 m-1">
    {children}
  </div>
);

export const Container: FC<{ className?: string; children: ReactNode }> = ({
  children,
  className = "",
}) => <div className={twMerge(`p-4`, className)}>{children}</div>;

export const Body: FC<{ className?: string; children: ReactNode }> = ({
  children,
  className = "",
}) => <div className={className}>{children}</div>;
export const Title: FC<
  { className?: string } & HTMLAttributes<HTMLHeadingElement>
> = ({ children, className = "", ...props }) => (
  // IF CHANGE THE FONT SIZE, check the CollectionCard to fixed the 2 lines truncate min height to 2 times the line height
  <h2 className={`text-xl font-medium mb-2 ${className}`} {...props}>
    {children}
  </h2>
);
export const Description: FC<{ className?: string; children: ReactNode }> = ({
  children,
  className,
}) => (
  <h3
    className={twMerge(`text-sm text-gray-500 leading-relaxed mb-4`, className)}
  >
    {children}
  </h3>
);

export const Price: FC<{ className?: string; children: ReactNode }> = ({
  children,
  className = "",
}) => (
  <div
    className={twMerge("text-xs text-gray-600 flex items-center", className)}
  >
    {children}
  </div>
);

export const Footer: FC<{ children: ReactNode }> = ({ children }) => (
  <div
    className="align-middle border-t border-gray-200 pt-4 flex items-center"
    style={{ minHeight: "85px" }}
  >
    {children}
  </div>
);

export const Tags: FC<{ className?: string; children: ReactNode }> = ({
  children,
  className = "",
}) => (
  <div className={twMerge("flex items-center mb-2", className)}>{children}</div>
);

export const Tag: FC<{ className?: string; children: ReactNode }> = ({
  children,
  className = "",
}) => (
  <div
    className={twMerge(
      "inline-block bg-gray-200 bg-opacity-70 text-gray-600 rounded px-3 py-1 text-xs mr-2 max-w-xs truncate md:overflow-visible md:whitespace-normal",
      className
    )}
  >
    {children}
  </div>
);
